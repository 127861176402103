<template>
  <div>
    <v-form @submit.prevent="submitResponse">
      <p class="mb-5">
        a) Click the buttons below to construct the proper
        <span class="text-bold">full electron configuration</span>
        for the indicated ion:
      </p>
      <p class="mb-3">
        i)
        <chemical-latex :content="atom1Name" />
      </p>
      <electron-configuration-input
        v-model="inputs.input1"
        :max-n="6"
        :max-occupation="10"
        :orbital-types="['s', 'p', 'd']"
      />

      <p class="mb-3">
        ii)
        <chemical-latex :content="atom2Name" />
      </p>
      <electron-configuration-input
        v-model="inputs.input2"
        :max-n="6"
        :max-occupation="10"
        :orbital-types="['s', 'p', 'd']"
      />

      <p class="mb-3">
        b) Which of
        <chemical-latex :content="atom1Name" />
        and
        <chemical-latex :content="atom2Name" />
        would have the larger radius?
      </p>
      <v-radio-group v-model="inputs.input3" class="pl-14" :disabled="!allowEditing">
        <v-radio key="cation" class="d-inline-block" value="cation">
          <template #label>
            <chemical-latex :content="atom1Name" />
          </template>
        </v-radio>
        <v-radio key="anion" class="d-inline-block" value="anion">
          <template #label>
            <chemical-latex :content="atom2Name" />
          </template>
        </v-radio>
      </v-radio-group>
    </v-form>
  </div>
</template>

<script>
import DynamicQuestionMixin from '../../mixins/dynamic-question';
import ElectronConfigurationInput from '../inputs/ElectronConfigurationInput';
import ChemicalLatex from '../displayers/ChemicalLatex';

export default {
  name: 'Question251',
  components: {
    ElectronConfigurationInput,
    ChemicalLatex,
  },
  mixins: [DynamicQuestionMixin()],
  data() {
    return {
      // Put a list of inputs or computed properties here to be sent for marking
      inputs: {
        input1: [],
        input2: [],
        input3: null,
      },
    };
  },
  computed: {
    versionVariable() {
      return this.taskState.getValueBySymbol('versionVariable').content;
    },
    versionData() {
      switch (this.versionVariable.value) {
        case 1:
          // 1 = Period 4/5 +/- 1
          return {
            atom1Name: 'Rb+',
            atom2Name: 'Br-',
          };
        case 2:
          // 2 = Period 4/5 +/- 2
          return {
            atom1Name: 'Sr^2+',
            atom2Name: 'Se^2-',
          };
        case 3:
          // 3 = Period 5/6 +/- 1
          return {
            atom1Name: 'Cs+',
            atom2Name: 'I-',
          };
        case 4:
          // 4 = Period 5/6 +/- 2
          return {
            atom1Name: 'Ba^2+',
            atom2Name: 'Te^2-',
          };
        default:
          return {
            atom1Name: null,
            atom2Name: null,
          };
      }
    },
    atom1Name() {
      return this.versionData.atom1Name;
    },
    atom2Name() {
      return this.versionData.atom2Name;
    },
  },
};
</script>
